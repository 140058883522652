import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { get } from 'react-hook-form'
import { Hidden, NoSsr } from '@material-ui/core'
import { capitalize } from '@material-ui/core/utils'
import * as Sentry from '@sentry/react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Route, Switch, useLocation } from 'wouter'

import { withNoSsr } from 'common/components/NoSsr'
import useClosure from 'common/hooks/useClosure'
import { ADDONS } from 'common/utils/conciergeProduct.constants'
import { GENERAL_ERROR_MESSAGE } from 'common/utils/data/messages'
import request from 'common/utils/request'
import CheckoutWrapper from 'artkive/components/Checkout/CheckoutWrapper'
import Divider from 'artkive/components/Checkout/Divider'
import CheckoutProductPreview from 'artkive/components/OrderSummary/CheckoutProductPreview'
import VipMembershipCard from 'artkive/components/OrderSummary/VipMembershipCard'
import PaymentOptions from 'artkive/components/PaymentOptions'
import Promo from 'artkive/components/Promo'
import { TrackerContext } from 'artkive/components/Tracker'
import useTracker from 'artkive/components/Tracker/useTracker'
import { BOX_CHECKOUT } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import { fetchBoxPrice as fetchPrice } from 'artkive/stores/ecom/api/fetchPrice'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'
import { BOX_PRODUCT_TYPE, PAYMENT_KINDS } from 'artkive/stores/product.constants'
import { USA_COUNTRY_KEY } from 'artkive/utils/addressValidationService'
import * as track from 'artkive/utils/tracker'

import OrderDetails from './Steps/OrderDetails'
import OrderDetailsAddons from './Steps/OrderDetailsAddons'
import ShippingAndPayment from './Steps/ShippingAndPayment'
import BoxOrderDetailsSummary from './BoxOrderDetailsSummary'
import MobileOrderDetails from './MobileOrderDetails'

const orderBox = async (payload) => {
  try {
    const response = await request.post(Routing.api_v2_orders_box_index(), { ...payload })

    return { response }
  } catch (error) {
    return { error }
  }
}

const addressTraits = (shippingInformation, normalize) => {
  if (!shippingInformation.shipping_zip) return undefined

  const traits = {
    city: shippingInformation.shipping_city,
    country: USA_COUNTRY_KEY,
    postalCode: shippingInformation.shipping_zip,
    state: shippingInformation.shipping_state,
  }
  if (normalize) {
    traits.city = traits.city?.replace(/\s/g, '')?.toLowerCase()
    traits.country = traits.country.toLowerCase()
    traits.state = traits.state?.toLowerCase()
  }
  return traits
}

// NOTE: normalization is required for FB conversion
const userTraits = (shippingInformation, normalize = true) => {
  const nameParts = (shippingInformation.name || '').split(' ')
  const { 0: firstName, [nameParts.length - 1]: lastName } = nameParts
  const traits = {
    phone: shippingInformation.phone_number,
    email: shippingInformation.email,
    name: shippingInformation.name,
    firstName: capitalize(firstName),
    lastName: capitalize(nameParts.length > 1 ? lastName : ''),
  }
  if (shippingInformation.shipping_zip) {
    traits.address = addressTraits(shippingInformation, normalize)
  }

  return traits
}

const buildCheckoutUtmParameters = (tracking) => ({
  utm_source: tracking.utmSource,
  utm_campaign: tracking.utmCampaign,
  utm_medium: tracking.utmMedium,
  utm_content: tracking.utmContent,
  utm_term: tracking.utmTerm,
})

const Checkout = withNoSsr(({
  productData,
  params,
  shippingProtectionPrice,
  productLinks,
  enableRecaptcha = false,
}) => {
  const [error, setError] = useState()
  const [trackAction] = useTracker()
  const isMobile = useMobileBreakpoint()

  const {
    cacheTrackingData,
    subscribe,
    unsubscribe,
  } = useContext(TrackerContext)

  const [pathname, navigate] = useLocation()

  const setLoader = useStoreActions(({ uiStore }) => uiStore.setLoader)

  const {
    clearAddons,
    reset,
    setPayment,
    setProduct,
    setStep,
    setStepActive,
    setShippingProtectionPrice,
    fetchProcessing,
    setProductMeta,
  } = useVariableActions(BOX_PRODUCT_TYPE, productData.uuid)

  const {
    validate,
    checkPromoForFutureUse,
    setPromo,
    tryToReusePromo,
    clearTracking,
  } = useStoreActions(({ promoStore }) => promoStore)

  const tracking = useStoreState(({ promoStore }) => promoStore.tracking)

  const {
    activeProcessing,
    activeStep,
    fullName,
    information,
    price,
    product,
    processing,
    promoCode,
    qty,
    vipSubscription,
    shippingProtection,
    visibleSteps,
    freeOrder,
    addOns,
    hasSubscription,
  } = useVariableState(BOX_PRODUCT_TYPE, productData.uuid)

  // is required to be able to create a price getter to pass it to payment options
  const [localPrice, setLocalPrice] = useState({})

  useEffect(() => {
    setLocalPrice(price)
  }, [price])

  const getPrice = useClosure(() => {
    return localPrice
  }, [localPrice])

  // const isVipMembershipAvailable = !user.user_subscription_plan?.version ||
  //   !user.user_subscription_plan?.version === 'COMP'
  const isVipMembershipAvailable = false

  const data = useMemo(() => {
    const checkoutOptions = {
      id: productData.id,
      cover_url: get(productData, 'cover_image.url', ''),
      preview_url: get(productData, 'preview_image.url', ''),
      name: get(productData, 'name', ''),
      back_link: `~${productLinks.landing}`,
      vip_membership: get(productData, 'properties.vip_membership', false),
      processing_time: get(productData, 'properties.processing_time', false),
      shipping_protection: get(productData, 'properties.shipping_protection', false),
      shipping_address: get(productData, 'properties.shipping_address', false),
      taxable: get(productData, 'properties.taxable', false),
      adjust_quantity: get(productData, 'properties.adjust_quantity', false),
      addon_sections: get(productData, 'properties.options.addon_sections', []),
      countries: get(productData, 'properties.countries', []),
    }

    const availableOrderDetails = [
      ...checkoutOptions.addon_sections,
      checkoutOptions.processing_time,
      checkoutOptions.shipping_protection,
    ].filter(Boolean)

    checkoutOptions.isOrderDetailsAvailable = availableOrderDetails.length > 1
    checkoutOptions.isSomeOrderDetailsAvailable = availableOrderDetails.length > 0
    checkoutOptions.forceExpressCheckoutOnFirstPage = availableOrderDetails.length <= 1

    checkoutOptions.isShippingDetailsAvailable = !!checkoutOptions.shipping_address
    checkoutOptions.hasSubscriptionAddon = (isVipMembershipAvailable && checkoutOptions.vip_membership) ||
      checkoutOptions.addon_sections.some(({ items }) => items.some(({ kind }) => kind === ADDONS.SUBSCRIPTION))

    return checkoutOptions
  }, [productData])

  // NOTE: it should be called only once but it is safe to do it multiple times
  useEffect(() => {
    console.log('set meta', { addons: data.addon_sections.flatMap(({ items }) => items) })
    setProductMeta({ addons: data.addon_sections.flatMap(({ items }) => items) })
  }, [data])

  const isOrderDetailsAvailable = data.isOrderDetailsAvailable || isMobile
  const isSidebarOrderDetailsAvailable = data.isSomeOrderDetailsAvailable && !data.isOrderDetailsAvailable
  const showPaymentOptions = price.total && (
    data.forceExpressCheckoutOnFirstPage || (!data.hasSubscriptionAddon || (!hasSubscription && activeStep.index > 0))
  )

  const buildPaymentMethod = (payment) => {
    if (!payment || Object.keys(payment).length === 0 || freeOrder) return

    const expArray = payment.cardExp.split('/')

    return {
      method: PAYMENT_KINDS.CREDIT_CARD,
      name_on_card: payment.name,
      credit_card_number: payment.cardNumber.replace(/\s+/g, ''),
      exp_month: expArray[0],
      exp_year: `20${expArray[1]}`,
      security_code: payment.cardCvc,
    }
  }

  const handleOrderDetails = async () => {
    await trackAction(BOX_CHECKOUT.NEXT_CLICK, { price })

    // set current step as valid
    setStep({ stepName: BOX_CHECKOUT_STEPS.ORDER_DETAILS, payload: { isComplete: true } })

    // update route
    navigate(`/${BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT}`)
  }

  const handleSuccessPurchase = useCallback(async (res, paymentKind, shippingInformation) => {
    const { id, pricing_object } = res.response.data.data.concierge_group
    const { promo_discount, promo_discount_details, total } = pricing_object

    track.identifyUser(userTraits(shippingInformation, false))

    /**
     *  NOTE As we don't add taxes right now total doesn't include them at all. Some consumers like
     *  ShareASale requires total without taxes
     */
    track.purchase({
      amount: total,
      discount: promo_discount,
      orderId: id,
      name: shippingInformation.name,
      productId: productData.id,
      product: 'box',
      promo: promo_discount_details.code,
      information: shippingInformation,
      paymentKind,
      tracking,
      context: { traits: userTraits(shippingInformation) },
    })

    try {
      await Promise.all([validate(), reset(), clearTracking()])
      await clearAddons()
      await checkPromoForFutureUse()
    } catch (error) {
      console.error('Checkout processing exception', JSON.stringify(error))
      try {
        Sentry.captureException(error)
      } catch (sentryError) {
        console.error('Sentry exception', JSON.stringify(sentryError))
      }
    } finally {
      // NOTE: as we don't refresh the page segment tracking works correctly
      const trackingData = btoa(JSON.stringify({ id, total }))
      const redirectParams = new URLSearchParams({ data: trackingData }).toString()
      navigate(`~${productLinks.confirmation}?${redirectParams}`)
      setLoader(false)
    }
  }, [productData, productLinks, tracking, validate, reset, clearTracking, clearAddons, checkPromoForFutureUse])

  const handlePlaceOrder = async (payment) => {
    setLoader(true)

    const paymentData = buildPaymentMethod(payment)
    try {
      const shippingInformation = {
        phone_number: information.phone.replace(/\D+/g, ''),
        shipping_address: information.address,
        shipping_address_details: information.address2,
        shipping_city: information.city,
        shipping_country: information.country,
        shipping_state: information.state,
        shipping_zip: information.zipCode,
        email: information.email,
        name: fullName,
      }
      const res = await orderBox({
        ...buildCheckoutUtmParameters(tracking),
        concierge: {
          quantity: qty,
          concierge_product_id: productData.id,
          billing_zip: payment?.billingZipCode,
          concierge_processing_id: activeProcessing?.id ?? 1,
          promo_code: promoCode,
          shipping_protection: shippingProtection,
          ...shippingInformation,
          add_ons: addOns,
        },
        payment: paymentData,
        'g-recaptcha-response-data': payment['g-recaptcha-response-data'],
      })

      if (res.error) {
        if (payment && Object.keys(payment).length !== 0) navigate(`/${BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT}`)

        setError(res.error?.response?.data?.message || GENERAL_ERROR_MESSAGE)
        // NOTE: this allows to read all error messages details from back-end
        console.log(JSON.stringify(res.error?.response?.data?.error || {}))

        setLoader(false)
      } else {
        handleSuccessPurchase(res, PAYMENT_KINDS.CREDIT_CARD, shippingInformation)
      }
    } finally {
      setLoader(false)
    }
  }

  const handlePayment = useCallback(async (data) => {
    setPayment(data)

    trackAction(BOX_CHECKOUT.NEXT_CLICK, { price })

    await handlePlaceOrder(data)
  }, [handlePlaceOrder, price])

  const handleApplePayClick = useCallback(() => {
    trackAction(BOX_CHECKOUT.APPLE_PAY_CLICK, { price })
  }, [price])

  const handleAddressChange = useClosure(async ({ administrativeArea, postalCode }) => {
    const payload = {
      concierge_product_id: product.id,
      add_ons: addOns,
      concierge_processing_id: activeProcessing?.id || processing.find((p) => p.default)?.id,
      promo_code: promoCode,
      quantity: qty,
      shipping_protection: shippingProtection,
      shipping_state: administrativeArea,
      shipping_zip: postalCode,
    }
    const data = await fetchPrice(payload)
    await setLocalPrice(data)
    return data
  }, [processing, product, addOns, activeProcessing, promoCode, qty, shippingProtection])

  const handleApplePayCheckout = useCallback(async ({ billingContact, shippingContact, token }) => {
    setLoader(true)

    try {
      const shippingInformation = {
        phone_number: shippingContact.phoneNumber?.replace(/\D+/g, ''),
        shipping_address: shippingContact.addressLines?.join(' '),
        shipping_address_details: null,
        shipping_country: shippingContact.countryCode,
        shipping_city: shippingContact.locality,
        shipping_state: shippingContact.administrativeArea,
        shipping_zip: shippingContact.postalCode,
        email: shippingContact.emailAddress,
        name: `${billingContact.givenName} ${billingContact.familyName}`,
      }
      const res = await orderBox({
        ...buildCheckoutUtmParameters(tracking),
        concierge: {
          quantity: qty,
          concierge_product_id: productData.id,
          billing_zip: billingContact.postalCode,
          concierge_processing_id: activeProcessing?.id ?? 1,
          promo_code: promoCode,
          shipping_protection: shippingProtection,
          ...shippingInformation,
          add_ons: addOns,
        },
        payment: { method: PAYMENT_KINDS.APPLE_PAY, token },
      })

      if (res.error) {
        setError(res.error?.response?.data?.message || GENERAL_ERROR_MESSAGE)
        // NOTE: this allows to read all error messages details from back-end
        console.log(JSON.stringify(res.error?.response?.data?.error || {}))
      } else {
        handleSuccessPurchase(res, PAYMENT_KINDS.APPLE_PAY, shippingInformation)
      }

      setLoader(false)
      return res
    } finally {
      setLoader(false)
    }
  }, [setLoader, handleSuccessPurchase, qty, productData, promoCode, activeProcessing, shippingProtection, addOns])

  useEffect(() => {
    setStep({ stepName: BOX_CHECKOUT_STEPS.ORDER_DETAILS, payload: { isVisible: isOrderDetailsAvailable } })
  }, [isOrderDetailsAvailable])


  const prepareEventData = useClosure(() => ({
    formType: 'information',
    kind: 'checkout',
    product: product.type,
    email: information.email,
    phone: information.phone,
    firstName: information.firstName,
    lastName: information.lastName,
  }), [information, product])

  useEffect(() => {
    cacheTrackingData(prepareEventData(), true)

    setStep({
      stepName: BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT,
      payload: {
        name: data.isShippingDetailsAvailable ? 'Shipping and Billing' : 'Billing',
      },
    })

    fetchProcessing()

    const { promo } = params
    // set shipping protection price based on params passed from rails erb
    setShippingProtectionPrice(shippingProtectionPrice)
    // set product pricing based on params passed from rails erb
    setProduct({
      id: productData.id,
      uuid: productData.uuid,
      title: productData.name,
      msrp: 0,
      price: productData.sale_price,
      image: data.preview_url || data.cover_url,
      isTaxable: data.taxable,
    })

    const payload = {
      code: promo || promoCode,
      product_id: productData.id,
      uuid: productData.uuid,
      productName: BOX_PRODUCT_TYPE,
    }
    // set promo
    if (promo) {
      setPromo(payload)
    } else {
      tryToReusePromo(payload)
    }

    const subscribeHandler = () => {
      cacheTrackingData(prepareEventData())
    }

    subscribe('blur', subscribeHandler)

    return () => {
      unsubscribe('blur', subscribeHandler)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)

    let alias = pathname.replace('/', '')
    const routeStepIndex = visibleSteps.findIndex((step) => step.alias === alias.toLowerCase())

    const defaultStepAlias = isOrderDetailsAvailable ?
      BOX_CHECKOUT_STEPS.ORDER_DETAILS : BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT

    // Check prev step completed
    if (routeStepIndex === -1 || !visibleSteps[routeStepIndex - 1]?.isComplete) {
      navigate(`/${defaultStepAlias}`, { replace: true })
      setStepActive({ stepName: defaultStepAlias })
    } else {
      setStepActive({ stepName: alias || defaultStepAlias })
    }
  }, [pathname])

  if (!activeStep)
    return null

  return (
    <CheckoutWrapper
      product={product}
      error={error}
      setError={setError}
      backTo={data.back_link}
      paymentOptions={showPaymentOptions && (
        <NoSsr>
          <PaymentOptions
            getPrice={getPrice}
            onApplePayCheckout={handleApplePayCheckout}
            onAddressChange={handleAddressChange}
            setError={setError}
            requestAddress={data.shipping_address}
            onApplePayClick={handleApplePayClick}
            disableApplePay={hasSubscription}
          />
        </NoSsr>
      )}
      details={(
        <>
          <Switch>
            <Route path={`/${BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT}`}>
              {isMobile ? (
                <MobileOrderDetails product={product} />
              ) : (
                <CheckoutProductPreview product={product} options={data} />
              )}
            </Route>
            <Route path={`/:rest*`}>
              <CheckoutProductPreview product={product} options={data} />
            </Route>
          </Switch>

          <Switch>
            <Route path={`/${BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT}`}>
              <Hidden smDown>
                {isVipMembershipAvailable && data.vip_membership && (
                  <>
                    <VipMembershipCard product={product} processing={processing} />
                    <Divider />
                  </>
                )}

                {isSidebarOrderDetailsAvailable && (
                  <>
                    <OrderDetailsAddons options={data} product={product} dense />
                  </>
                )}

                {isOrderDetailsAvailable && (
                  <>
                    <Divider my={3} />
                    <BoxOrderDetailsSummary product={product} />
                  </>
                )}
              </Hidden>
            </Route>

            <Route path={`/:rest*`}>
              {isVipMembershipAvailable && data.vip_membership && (
                <VipMembershipCard product={product} processing={processing} />
              )}
            </Route>
          </Switch>
          <Hidden smDown>
            <Divider my={4} />

            <Promo product={product} disabled={vipSubscription} />
          </Hidden>
        </>
      )}
    >
      <Switch>
        <Route path={isOrderDetailsAvailable ? `/${BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT}` : '/:rest*'}>
          <ShippingAndPayment
            isLoading={false}
            onSubmit={handlePayment}
            product={product}
            options={data}
            setError={setError}
            captcha={enableRecaptcha}
          />
        </Route>

        {isOrderDetailsAvailable && (
          <Route path={`/:rest*`}>
            <OrderDetails
              product={product}
              options={data}
              steps={visibleSteps}
              onSubmit={handleOrderDetails}
              continueButtonText={data.hasSubscriptionAddon ? 'Continue' : 'Continue with Credit Card'}
            />
          </Route>
        )}
      </Switch>
    </CheckoutWrapper>
  )
})

Checkout.propTypes = {
  boxPrice: PropTypes.number,
  params: PropTypes.object,
  shippingProtectionPrice: PropTypes.number,
  productData: PropTypes.object,
  productLinks: PropTypes.object,
  enableRecaptcha: PropTypes.bool,
}

export default memo(Checkout)
